@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@font-face {
  font-family: 'CenturyGothic';
  src: url(/static/media/CenturyGothic.09f6b2ba.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBold';
  src: url(/static/media/CenturyGothicBold.7bea0c92.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicItalic';
  src: url(/static/media/CenturyGothicItalic.87a92153.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBoldItalic';
  src: url(/static/media/CenturyGothicBoldItalic.abd76d61.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicLight';
  src: url(/static/media/CenturyGothicLight.8917856b.ttf) format('TrueType');
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url(/static/media/Montserrat-Regular.ee653992.ttf) format('TrueType');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url(/static/media/Montserrat-Bold.ade91f47.ttf) format('TrueType');
  font-weight: bold;
  font-style: normal;
}

:root {
  --pink900: #de0c4b;
  --pink800: #ff5776;
  --brand900: #002133;
  --brand800: #003756;
  --brand700: #0086cb;
  --brand600: #5cb5fe;
  --brandPrimary: #006eab;
  --brandPrimaryLight: #e6f1f7;
  --brandPrimaryDark: #004466;
  --brandSecundary: #004d78;
  --brandSecundaryLight: #80b7d5;
  --brandSecundaryDark: #00639a;
  --yellow900: #fccc00;
  --yellow800: #ffff50;
  --lilac900: #7a1fa0;
  --lilac800: #ad52d2;
  --purple900: #6600f7;
  --purple800: #a248ff;
  --grey600: #b0afaf;
  --grey500: #cfcfcf;
  --grey400: #ffffff;
  --green100: #89d747;
  --greyPrimary: #575757;
  --greyNeutral: #767676;
  --greyLight: #cfcfcf;
  --red100: #dd4646;
  --red200: #ff5776;
  --red300: #b30142;
  --white100: #ffffff;
  --black100: #000000;
}

* {
  -webkit-overflow-scrolling: inherit;
}

body {
  margin: 0;
  font-family: 'CenturyGothic', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiInputBase-root {
  font-family: 'CenturyGothic', sans-serif !important;
  width: 100% !important;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.MuiButton-outlined {
  border: 1px solid #de0c4b !important;
}

.Toastify__toast-body {
  font-family: 'CenturyGothic', sans-serif !important;
}

.Toastify__toast {
  padding: 16px !important;
}

.Toastify__toast--default {
  border-radius: 20px;
}

.custom__tooltip {
  border-radius: 16px !important;
}

[tooltip-text] {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

[tooltip-text]:hover::before {
  display: block;
  content: attr(tooltip-text);
  background: #00198a;
  border-radius: 8px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: -30px;
  line-height: 16px;
  width: 350px;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  [tooltip-text]:hover::before {
    left: -96px;
    width: 296px;
  }
}

[tooltip-text]:hover::after {
  display: block;
  content: '';
  font-size: 0;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  position: absolute;
  top: -18px;
  left: -4px;
  z-index: 1;
  box-sizing: border-box;
  width: 0;
  height: 0;
  border-top: 17px solid #00198a;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
}

@media only screen and (max-width: 320px) {
  .typeform-popover-wrapper iframe {
    width: 90% !important;
  }
}

